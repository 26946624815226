import { Component, OnDestroy} from '@angular/core';
import { Router,NavigationStart, Event as NavigationEvent } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Role, User } from './Models';
import { AuthService } from './service/auth.service';
import { TranslateConfigService } from './translate-config.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  /**
   * current user in the app
   */
  user: User;
  /**
   * get the value of the item in teh menu that is selected
   */
  currentLink:  string;
  /**
   * boolean of the user statut get in the autheService
   */
  isAuth: boolean;
  /**
   * list of user roles
   */
  roles: Role[];
  /**
   * list of all features
   */
  features=['advertising-element','device-groups','device','sequence','about','role'];
params: any;
  constructor(
     private translateConfig: TranslateConfigService,
     private router: Router,
     private menuCtrl: MenuController,
     private authService: AuthService){
     this.user=this.authService.user;
     this.router.events.subscribe(
     (event:  NavigationEvent) => {
       if(event instanceof NavigationStart) {
         this.setSelectedLink(event.url);
       }
     });
    this.translateConfig.setLanguage('en');
    this.authService.isAuth.subscribe(value=>{
      this.isAuth=value;
    });
  }
  /**
   * close the menu and navigate to the selected feature...
   */
  setItem(item: string){
      setTimeout(() => {
        this.closeMenu();
        this.router.navigate(['/'+item]);
      }, 300);
  }

  /**
   * check the value of the router link and  set the value of the curent selected link when the url change
   */
  setSelectedLink(routerLink: string){

    for (let i=0; i++; i<this.features.length){
      if(this.features[i].includes(routerLink)){
        this.currentLink=this.features[i];
      }
    }
  }

  async closeMenu(){
    await this.menuCtrl.close();
  }

/**
 * signout in the authservice and clair all the user cookies
 */
 async signOut(){
  this.authService.signOut();
  await  this.closeMenu();
  localStorage.clear();
  location.reload();
}

 /**
  * check if the user has a specific roles or not
  */
  checkRoles(roleName){
    return this.authService.checkRoles(roleName);
  }
  ngOnDestroy(): void {
    this.authService.isAuth.unsubscribe();
  }
}
