import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController} from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, Observable, EMPTY, from, lastValueFrom, delay } from 'rxjs';
import * as cryptoJS from 'crypto-js';
import { AlertService } from './alert.service';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private alertService: AlertService,
    private loadingCtrl: LoadingController,
    private cookieService: CookieService,

  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleInternetConnectionCheck(request, next)).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
          throw error;
      }));
  }

  async handleInternetConnectionCheck(request: HttpRequest<any>, next: HttpHandler) {
    const bytes  = cryptoJS.AES.decrypt(this.cookieService.get('token'), 'RanitesP2022');
    const token= bytes.toString(cryptoJS.enc.Utf8);
    let isconnected = true;
    let del = 0;
    const authReq = request.clone({
      headers: request.headers.set('authorization','Bearer '+token),
    });
    if (navigator.onLine || request.url.includes('assets')) {
      return lastValueFrom(next.handle(authReq));
    } else {
      isconnected = false;
      del = 500;
      return this.showLoader(isconnected,del);
    }
  }
  async showLoader(connexion: boolean, del: number) {
    if (!connexion) {
      delay(del);
      let topLoader = await this.loadingCtrl.getTop();
      let count = 0;
      while (topLoader) {
        if (!(await topLoader.dismiss())) {
          count += 1;
        }
        if (count < 10) {
          topLoader = await this.loadingCtrl.getTop();
        } else {
          topLoader = null;
        }
      }
      this.presentToast();
      return EMPTY;
    }
  }

  async showToast(del: number){
    delay(del);
    let topLoader = await this.loadingCtrl.getTop();
    let count = 0;
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        console.log('Could not dismiss the topmost loader. Aborting...');
        count += 1;
      }
      if (count < 10) {
        topLoader = await this.loadingCtrl.getTop();
      } else {
        topLoader = null;
      }
    }

    this.presentToast();
  }

  async presentToast() {
    this.alertService.presentToast(this.alertService.noConnection,'warning','bottom');
  }

}
