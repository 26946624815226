import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AboutComponent } from './about/about.component';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AuthGuardService } from './guard/auth.guard';
import {  HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './service/auth.service';
import { TranslateConfigService } from './translate-config.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GuardAvoidGuard } from './guard/guard-avoid.guard';
import { HttpInterceptorService } from './service/http-interceptor.service';
import { AlertService } from './service/alert.service';
import { DeviceGuard } from './guard/device.guard';
import { DeviceGroupGuard } from './guard/device-group.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function languageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent,AboutComponent],
  imports: [ BrowserModule,
            IonicModule.forRoot(),
             AppRoutingModule,
            HttpClientModule,
            IonicModule.forRoot(),
            FormsModule,
            ReactiveFormsModule,
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: (languageLoader),
                deps: [HttpClient]
              }
        })
    ],
    entryComponents: [],

  providers: [
    CookieService,
    AuthGuardService,
    DeviceGuard,GuardAvoidGuard,
    DeviceGroupGuard,
    AlertService,
    AppVersion,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthService,
    TranslateConfigService,
   { provide: RouteReuseStrategy,
     useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
