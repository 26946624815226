import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

  toastMessageEmiter = new Subject<any>();


  apply='';
  close='';
  cancel='';
  confirm='';
  alert='';
  print='';
  noConnection: '';
  constructor(private alertController: AlertController,
    private translate: TranslateService,
    private toastController: ToastController) {
    this.translate.stream('Alert.apply').subscribe(data=>{
      this.apply=data;
    });

    this.translate.stream('Alert.close').subscribe(data=>{
      this.close=data;
    });

    this.translate.stream('Alert.cancel').subscribe(data=>{
      this.cancel=data;
    });

    this.translate.stream('Alert.confirm').subscribe(data=>{
      this.confirm=data;
    });
    this.translate.stream('Alert.alert').subscribe(data=>{
      this.alert=data;
    });
    this.translate.stream('Alert.print').subscribe(data=>{
      this.print=data;
    });
    this.translate.stream('Global.no_connection').subscribe(data=>{
      this.noConnection=data;
    });
   }

   /**
    * alert to display to display simple information to the user
    */
  async simpleAlert(word){
    const alert = await this.alertController.create({
      cssClass: 'ion-text-center',
      header: this.alert,
      //subHeader: 'Subtitle', this is not relevant for us now
      message: word,
      buttons:
      [{text: this.close,
        id: 'alert_close_bt'}
      ]
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  async confirmPrintAlert(word): Promise<boolean>{
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    const alert = await this.alertController.create({
      cssClass: 'ion-text-center',
      backdropDismiss:false,
      header: this.confirm,
      message: word,
      buttons: [
        {
          text: this.cancel,
          role: 'CANCEL',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => resolveFunction(false)
        }, {
          text: this.print,
          id: 'confirm-button',
          handler: () => resolveFunction(true)
        }
      ]
    });

    await alert.present();
    await alert.onDidDismiss().then();
  await alert.present();
  return promise;
  }

  async confirmAlert(word: string,okButton: string): Promise<boolean>{
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    const alert = await this.alertController.create({
      cssClass: 'ion-text-center',
      header: this.confirm,
      backdropDismiss:false,
      message: word,
      buttons: [
        {
          text: this.cancel,
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => resolveFunction(false)
        }, {
          text: 'ok',
          id: 'confirm-button',
          handler: () => resolveFunction(true)
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss().then();
    await alert.present();
  return promise;
  }

  /**
   * toast to display success message as a toast
   */
  async presentToast(msg: string,toastColor: string, toastPosition) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color: toastColor,
      position: toastPosition,
      cssClass: 'ion-text-center',
    });
    toast.present();
  }

  /**
   *alert to display error message
   */
  async errorAlert(word: string) {
    const alert = await this.alertController.create({
      cssClass: 'the-custom-class',
      header: this.alert,
      //subHeader: 'Subtitle', this is not relevant for us now
      message: '<div> <ion-icon   name="close-circle-outline" color="danger"></ion-icon></div><ion-text >'+  word  + '</ion-text>' ,
      buttons: [
          {   text     : this.close,
              role     : 'cancel',
              cssClass : 'dark',
          }
        ]
    });
    await alert.present();
    await alert.onDidDismiss();
  }
  raiseToastEmitter(data: any){
    this.toastMessageEmiter.next(data);
}

}


