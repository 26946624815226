import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AuthGuardService } from './guard/auth.guard';
import { DeviceGuard } from './guard/device.guard';
import { GuardAvoidGuard } from './guard/guard-avoid.guard';
import { SequenceGuard } from './guard/sequence.guard';
import { DeviceGroupGuard } from './guard/device-group.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate:[GuardAvoidGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'about',
    component:AboutComponent,
    canActivate:[AuthGuardService]
  },


  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'advertising-element',
    loadChildren: () => import('./manage-advertising-element/manage-advertising-element.module').then(
       m => m.ManageAdvertisingElementPageModule)
  },
  {
    path: 'device',
    canActivate: [AuthGuardService, DeviceGuard],
    loadChildren: () => import('./device/device.module').then( m => m.DevicePageModule)
  },
  {
    path: 'device-groups',
    canActivate: [AuthGuardService, DeviceGroupGuard],
    loadChildren: () => import('./device-groups/device-groups.module').then( m => m.DeviceGroupsPageModule)
  },
  {
    path: 'sequence',
    canActivate: [AuthGuardService,SequenceGuard],
    loadChildren: () => import('./sequence/sequence.module').then( m => m.SequencePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
