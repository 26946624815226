/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject,firstValueFrom} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Role, User } from '../Models';
import * as cryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn:  'root'
})

export class AuthService {
  /**
   * Base url of usm that can change
   */
  usmBaseUrl=environment.usmApiUrl;
  /**
   * Base url of adm that can change
   */
  admBaseUrl=environment.admApiUrl;
  /**
   * curent user using the app
   */
  user= new User();
  /**
   * array of all roles of the user
   */
  roles: Role[] =[];
   /**
    * boolean to know if the user is connected or not
    */
  isAuth: BehaviorSubject<boolean>= new BehaviorSubject<boolean>(false);
  /**
   * application's language
   */
  language='en_US';
  /**
   * message to display if the user has not role
   */
  requestUrl = 'https://144.91.108.116:3026/';


  noRole: string;
  roleError: string;
  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private router: Router){
      this.handleUrl();
      this.translateService.stream('Global.noPermission').subscribe(data=>{
        this.noRole=data;
      });
      this.translateService.stream('Global.roleError').subscribe(data=>{
        this.roleError=data;
      });
    if(cookieService.check('userId')&&cookieService.check('userName')&&cookieService.check('userEmail')){
      try {
        this.retrieveUserData();
        this.getUserRoles().then(result=>{
          this.handleRoles(result);
        });
        this.signIn();
      } catch (error) {
        this.isAuth=new BehaviorSubject<boolean>(false);
      }
    }
  }

  /**
   * function called when the user login to get the user information , create cookies and get the user roles.
   */
  async getUser(userName: string,passWord: string){
    try {
      const data=await firstValueFrom(this.login(userName, passWord)) ;
      this.user.userId=data.user.userId;
      this.user.userName=data.user.firstName+' '+data.user.lastName;
      this.user.userImage=data.user.userImage;
      this.user.userEmail=data.user.email;
      this.user.token=data.id_token;
      this.user.organisationId=data.user.organisationId;
      this.createCookies(data.expires_in);
      const roles =await this.getUserRoles();
      console.log(roles);
      this.handleRoles(roles);
      this.signIn();
      return this.user;
    } catch (error) {
      this.signOut();
      if(error.toString().includes('user has no role')){
        throw new Error ('user has no role');
      }else{
        throw new Error( error.error.Message);
      }
    }
  }
  /**
   * get the user informations in the cookies
   */
  retrieveUserData(){
    // eslint-disable-next-line radix
    this.user.userId=parseInt(this.decryptData(this.cookieService.get('userId')));
    this.user.userName=this.decryptData(this.cookieService.get('userName'));
    this.user.userEmail=this.decryptData(this.cookieService.get('userEmail'));
    this.user.organisationId=this.decryptData(this.cookieService.get('orgId'));
  }

  /**
   * add or remove the '/' on the base url that the envirronement varialbles will provide
   */
  handleUrl(){
    if(this.usmBaseUrl.slice(this.usmBaseUrl.length - 1)!=='/'){
      this.usmBaseUrl=this.usmBaseUrl+'/';
    }
    if(this.admBaseUrl.slice(this.admBaseUrl.length - 1)!=='/'){
      this.admBaseUrl=this.admBaseUrl+'/';
    }
  }

  /**
   * request to get user information from the backend
   */
  login(email: string, passWord: string){
    return this.http.get<any>(this.usmBaseUrl+'api/validateuser/0/0/' + email + '/ /' + passWord + '/ /');
  }

  createCookies(timeOut: number){
    this.cookieService.set('userId', this.encryptData(this.user.userId+''),{ expires:  timeOut });
    this.cookieService.set('userName', this.encryptData(this.user.userName),{ expires:  timeOut });
    this.cookieService.set('userEmail', this.encryptData(this.user.userEmail),{ expires:  timeOut });
    this.cookieService.set('token',this.encryptData(this.user.token),{ expires:  timeOut });
    this.cookieService.set('orgId', this.encryptData(this.user.organisationId+''),{ expires:  timeOut});
  }
  /**
   * encrypt data before save it in the cookies for security reason
   */
  encryptData(text: string){
    return cryptoJS.AES.encrypt(text, 'RanitesP2022').toString();
  }
  /**
   * decrypt data after get in the cookies
   */
  decryptData(encryptText: string){
    const bytes  = cryptoJS.AES.decrypt(encryptText, 'RanitesP2022');
    return bytes.toString(cryptoJS.enc.Utf8);
  }

  /**
   * get user roles
   */
  async getUserRoles(){
    try {
      const groupIdList=await this.getGroupId();
      let list='';
      for(const groupId of groupIdList){
        list=list+'userGroups='+groupId.toString()+'&';
      }
       return await this.admRequestTemplate('userRole/get?','GET',list);
    } catch (error) {
      console.log(error,'error to get roles');
    }
  }
  /**
   * function to use like template for each admRequest
   * controlerUrl: the url of the controller
   * requestMethod: the request method to use (POST,GET,...)
   * parameter: the request parameters
   * requestBody: the JSON object to send with the request
   */
  async admRequestTemplate(controlerUrl: string,requestMethod: string, parameter: string,requestBody?){
    let init: RequestInit;
    if(requestBody){
      init= {
        method: requestMethod,
        body: JSON.stringify(requestBody),
        headers: {'Content-Type':'application/json',
        mode: 'no-cors'}
      };
    }else{
      init= {
        method: requestMethod
      };
    }
    return JSON.parse(await fetch(this.admBaseUrl+controlerUrl+parameter, init)
      .then(response => response.text())
      .then(result => result)
      .catch(error => { console.log('ERROR', error); throw new Error(error); }));
  }
  /**
   * handle roles comming from the ADM controller
   */
  handleRoles(roles){
    console.log(roles);
    if(roles!==undefined){
      for(const item of roles.data){
        const role=new Role();
        role.roleId=item.roleid;
        role.roleName=item.rolename;
        role.orgId=item.org_id;
        role.roleDescription=item.description;
        this.roles.push(role);
      }
    }else{
      this.alertService.simpleAlert(this.roleError);
      this.router.navigate(['/home']);
      this.signOut();
    }
    if(this.roles.length===0){
      this.alertService.simpleAlert(this.noRole);
      this.signOut();
      this.router.navigate(['/home']);
      throw new Error('user has no role');

    }else{
      this.isAuth=new BehaviorSubject<boolean>(true);
    }
  }
  /**
   * get the user groups form the usm system base on the org, the user id and retern it like a list of number
   */
  async getGroupId(){
    const grouIdList: number []=[];
    try {
      const data=await firstValueFrom(
        this.http.get<any>(this.usmBaseUrl+'api/usergroups/get_byuserid/'+this.user.organisationId+'/0/'+this.user.userId));
        for(const group of data){
          grouIdList.push(group.groupId);
        }
    } catch (error) {
      console.log(error);
    }
    return grouIdList;
  }
  /**
   * set the status for the guard when he log in
   */
  signIn() {
      return new Promise(
      resolve => {
          setTimeout(
          () => {
            this.isAuth.next(true);
            resolve(true);
          }, 1
          );
      }
      );
  }
  /**
   * logout the user
   */
    signOut() {
        this.cookieService.deleteAll();
        this.isAuth.next(false);
  }
  /**
   * check if the user has a specific roles or not
   */
  checkRoles(roleName){
    let hasRole;
    for (const role of this.roles){
      if(role.roleName.includes(roleName)===true){
        hasRole=true;
      }
    }
    return hasRole;
  }
}

