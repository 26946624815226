import { Component, OnInit } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  version='1.0.0';

  constructor(private appVersion: AppVersion) { }

 async ngOnInit() {
    try {
      this.version=await this.appVersion.getVersionNumber();
    } catch (error) {
      console.log(error,'');
    }
  }

}
