/**
 * user class
 */
export class User {
    /**
     * id of the user
     */
    userId: number;
    /**
     * user image
     */
    userName: string;
    /**
     * user email
     */
    userImage: string;
    /**
     * user email
     */
    userEmail: string;
    /**
     * id of the organisation
     */
    organisationId: string;
    /**
     * token of the user
     */
    token: string;
    constructor(){
        this.userId=0;
        this.userName='';
        this.userEmail='';
        this.userImage='';
        this.organisationId='';
        this.token='';
    }
}

/**
 * class for each sequence in a list of sequence
 */
export class SequenceItem{
    /**
     * id of the sequence
     */
    sequenceId: number;
    /**
     * title of the sequence
     */
    sequenceTitle: string;
    /**
     * sequence start date
     */
    sequenceStart: string;
    /**
     * sequence end date
     */
    sequenceEnd: string;
    /**
     * sequence creator
     */
    creator: string;
    /**
     * sequence created date
     */
    createdDate: string;
    /**
     * sequence statut
     */
    status: string;
    /**
     * function to initialize a new sequence
     */
    constructor(){
       this.sequenceId=0;
       this.sequenceTitle='';
       this.sequenceStart='';
       this.sequenceEnd='';
       this.createdDate='';
       this.creator='';
       this.status='';

    }
}
export class Sequence extends SequenceItem{

}

/**
 * Class for each device of the organisation
 */
export class Device{
    /**
     * Id of the device
     */
    deviceId: number;
    /**
     * name of the device
     */
    deviceName: string;
    /**
     * Property to know if the device has already download a sequence
     */
    deviceStatus: string;
    /* */
    /**
     * constructor to initialize a new device
     */
    constructor(){
        this.deviceId=0;
        this.deviceName='';
        this.deviceStatus='';
    }
}
/**
 * Device group to add a group of device in a sequence
 */
export class SequenceDeviceGroup{
    /**
     * id of the device group
     */
    groupId: number;
    /**
     * name of the device group
     */
    groupName: string;
    /**
     * list of device in the device group
     */
    devices: Device[];
    /**
     * define if the user display the device in the device group or not
     */
    /**
     * current page of the device to make a api
     */
    deviceListPage: number;
    /**
     * size to use to get devices from the backend
     */
    deviceListSize: number;
    /**
     * booelan to know if the device group is expanded or not
     */
    showDevice: boolean;
    /**
     * constructor to create a new object
     */
    constructor(){
        this.showDevice=false;
        this.groupId=0;
        this.deviceListPage=1;
        this.deviceListSize=8;
        this.groupName='';
        this.devices=[];
    }
}

/**
 * class use to send adv element to a sequence
 */
export class SimpleElement{
    /**
     *id of the elm
     */
     elementId: number;
     /**
      * duration that element will be displayed
      */
    duration: number;
}

/**
 * advertising element added in a sequence
 */
export class SequenceElement extends SimpleElement{
     /**
      * name of the sequence
      */
      elementName: string;
    /**
     * position of the element in sequence
     */
    position: number;
    /**
     * constructor to initialize a new sequence element
     */
    constructor(){
        super();
        this.elementId=0;
        this.elementName='';
        this.duration=0;
        this.position=0;
    }
}
/**
 * Class of all roles that we can have in the app
 */
export class Role{
    /**
     * id of the role
     */
    roleId: number;
    /**
     * name of the role
     */
    roleName: string;
    /**
     * org id
     */
    orgId: number;
    /**
     * description of the role
     */
    roleDescription: string;
    constructor(){
        this.roleId=0;
        this.roleName='';
        this.orgId=0;
        this.roleDescription='';
    }
}
export class EditData {
    constructor(public key: string,public value: string){
        this.key=key;
        this.value=value;
    }
}

export interface IElementPage {
    orgId: number;
    page: number;
    size: number;
}

export class ITypeSelected {
    elementTypeId: number;
    elementTypeName: string;
}

export class AdvertisingElement {
    elementId: string;
    elementName: string;
    elementTpype: string;
    createdby?: string;
    creationDate: string;
    elementData: string;
    flmId:  string;
}


export class FlmFileName {
   bucketname: string;
   externalFileName: string;
}

export class FlmFileUrl {
    url: string;
    metaData: string;
}

export class AddDeviceGroup{
  deviceID: number;
  deviceTypeName: string;
  deviceName: string;
  deviceDescription: string;
}
export class Devices {
    public deviceid!: number;
    public id!: number;
    public device!: string;
    public orgId!: number;
    public devicename!: string;
    public createdBy!: string;
    public createdname!: string;
    public devicedescription!: string;
    public devicesubname!: number;
    public devicepage!: number;
    public devicesize!: number;
    public devicetype!: string;
    public terminalId!: string;
    public detail!: boolean;
    public deviceGroup!: Group[];
    constructor(){
        this.devicepage=1;
        this.devicesize=10;
        this.orgId=0;
        this.deviceid=0;
     }
}
export class Group {
   public devid: number;
   public grpid: number;
   public orgId: number;
   public devicegrouppage: number;
   public devicegroupsize: number;
   public grpname: string;
   public grpdescription: string;
    constructor() {
        this.devid=0;
        this.grpid=0;
        this.devicegrouppage=1;
        this.devicegroupsize=10;
        this.orgId=0;
        this.grpname='';
    }
  }
  export class Select {
    public devtypeid: number;
    public devtypename: string;
    public devtypedescription: string;
     constructor() {
         this.devtypeid=0;
         this.devtypedescription='';
         this.devtypename='';
     }
   }
   export class Dev
   {
        public dvid: number;
        public orgId: number;
        public devpage: number;
        public devsize: number;
        public gpid: number;
        public name: string;
        public description: string;
        public subname: number;
        constructor() {
            this.dvid=0;
            this.gpid=0;
            this.devpage=1;
            this.devsize=10;
            this.orgId=1;
            this.name='';
        }
    }
    export class Grp {
        public iD!: number;
        public id!: number;
        public group!: string;
        public groupname!: string;
        public grouppage!: number;
        public groupsize!: number;
        public groupdescription!: string;
        public groupsubname!: number;
        public createdBy!: string;
        public created!: Date;
        public orgId!: string;
        public detail!: boolean;
        public devices!: Dev[];
        public device!: Gpdetails[];
        constructor(){
         this.grouppage= 1;
        this.groupsize= 10;
        }
    }
    export class Gpdetails {
        public gpdetailid: number;
        public groupid: number;
        public orgId: number;
        public grpdetailpage: number;
        public grpdetailsize: number;
        public name: string;
        public description: string;
        public devicetype: string;
         constructor() {
             this.gpdetailid=0;
             this.orgId=0;
             this.groupid=0;
             this.grpdetailpage=1;
             this.grpdetailsize=10;
         }
       }
