import { Injectable } from '@angular/core';
import { CanActivate,UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SequenceGuard implements CanActivate {
  constructor(private authService: AuthService) {
     }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.checkRoles('Sequence');
  }
}
