
import {  CanActivate, Router } from '@angular/router';
import { Observable} from 'rxjs';
import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn:'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,private router: Router) { }

  canActivate(): Observable <boolean> | Promise<boolean> | boolean
   {
    if(this.authService.isAuth.value) {
      return true;
    } else {
      this.router.navigate(['/home']);
       return false;
    }
  }
}
