import { Injectable } from '@angular/core';
import { CanActivate, Router,UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardAvoidGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) { }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!this.authService.isAuth.value){
      return true;
    }else{
      this.router.navigate(['/sequence']);
      return false;
    }
  }
}
